* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

ul::-webkit-scrollbar {
  display: none;
}

/* NAVBAR STYLING */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  height: 40px;
  width: 100%;
  position: fixed;
  z-index: 3;
  transition: 0.4s;
}

.dark-nav {
  background: #000;
  transition: 0.4s;
}

.logo-list {
    display: flex;
    align-items: center;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.logo {
  width: 60px;
  
}

.list-wrapper {
  height: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: 10px;
  display: none;
}

.list-item {
  margin: 0 5px;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 400;
}

.profile {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.fa-search, .fa-bell {
    color: #fff;
    margin-right: 20px;
}

.profile-avatar {
    width: 25px;
}


/* HERO STYLING */
.hero-container {
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 275px;
  background-size: cover;
  background-position: top;
}

.hero-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.hero-h2 {
  color: #fff;
  font-size: 1.1rem;
}

.hero-p {
  font-size: 0.7rem;
  text-align: left;
  width: 200px;
  color: #fff;
  margin: 5px 0 10px 0;
}

.play-btn {
  border: none;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  margin-right: 5px;
}

.more-info-btn {
  border: none;
  background-color: rgba(109, 109, 110, 0.7);
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

.play-btn:hover,
.more-info-btn:hover {
  opacity: 0.8;
}

.fa-play {
  margin-right: 10px;
  font-size: 0.8rem;
}

.fa-info-circle {
  margin-right: 10px;
  font-size: 0.9rem;
}

/* ROWS STYLING */
.row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 30px 20px;
}

.group-of-rows {
  margin-top: -40px;
}

h3 {
  color: #fff;
  font-size: 0.9rem;
}

.row-wrapper {
  width: 100%;
  height: 205px;
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 5px;
}

.row-img {
  width: 125px;
  margin-right: 5px;
  border-radius: 5px;
}

.row-img:hover {
  scale: 1.05;
  transition: 0.3s;
}

/* FOOTER STYLING */
.footer-container {
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
}

.social {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
}

.fab {
    color: #bebebe;
    font-size: 1.5rem;
    margin-right: 10px;
}

.link-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #bebebe;
    font-size: .8rem;
}

.link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 10px 10px 20px;
}

.copyright {
    color: #bebebe;
    font-size: .6rem;
    width: 100%;
    text-align: left;
    margin: 0 0 10px 20px;
}

@media screen and (min-width: 768px) {
    .nav-container {
        height: 50px;
    }
    .logo-list {
        display: flex;
        align-items: center;
    }
    
    .logo-wrapper {
        margin-left: 40px;
        transition: 0.4s;
    }
    
    .logo {
      width: 75px;
      transition: 0.4s;
    }
    
    .list-item {
      margin: 0 6px;
      font-size: 0.8rem;
      transition: 0.4s;
    }
    
    .profile {
        margin-right: 40px;
        transition: 0.4s;
    }
    
    .fa-search, .fa-bell {
        color: #fff;
        font-size: 1.1rem;
        margin-right: 20px;
        transition: 0.4s;
    }
    
    .profile-avatar {
        width: 30px;
        transition: 0.4s;
    }

  .list-wrapper {
      display: initial;
      height: 100%;
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-left: 10px;
      transition: 0.4s;
  }
  
  .hero-container {
    height: 350px;
    transition: 0.4s;
  }

  .hero-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;
    transition: 0.4s;
  }

  .hero-h2 {
    font-size: 1.4rem;
    transition: 0.4s;
  }

  .hero-p {
    font-size: 0.9rem;
    width: 250px;
    transition: 0.4s;
  }

  .row-container {
    margin-left: 40px;
    transition: 0.4s;
  }

  .footer-container {
    width: 100%;
    overflow: hidden;
}

.social {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    transition: 0.3s;
}

.fab {
    color: #bebebe;
    font-size: 2rem;
    margin-right: 15px;
    transition: 0.3s;
}

.link-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #bebebe;
    font-size: 1rem;
    transition: 0.3s;
}

.link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 10px 10px 40px;
    transition: 0.3s;
}

.copyright {
    color: #bebebe;
    font-size: .7rem;
    width: 100%;
    text-align: left;
    margin: 0 0 10px 40px;
    transition: 0.3s;
}
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .hero-container {
    height: 450px;
    transition: 0.4s;
  }

  .hero-h2 {
    font-size: 2rem;
  }

  .hero-p {
    font-size: 1.1rem;
    width: 300px;
    transition: 0.4s;
  }
}

@media screen and (min-width: 1024px) {
  .hero-container {
    height: 500px;
    transition: 0.4s;
  }

  .hero-h2 {
    font-size: 2.5rem;
  }

  .hero-p {
    font-size: 1.3rem;
    width: 350px;
  }

  .row-container {
    margin-left: 40px;
    transition: 0.4s;
  }

  .play-btn {
    padding: 8px 30px;
    margin-right: 8px;
    transition: 0.4s;
  }

  .more-info-btn {
    padding: 8px 30px;
    transition: 0.4s;
  }

  h3 {
    color: #fff;
    font-size: 1.4rem;
    transition: 0.4s;
  }
}

@media screen and (min-width: 1336px) and (orientation: landscape) {
  .hero-container {
    height: 600px;
  }

  .hero-h2 {
    font-size: 3.3rem;
  }

  .hero-p {
    font-size: 1.2rem;
    width: 450px;
  }

  .play-btn {
    padding: 10px 35px;
    margin-right: 10px;
  }

  .more-info-btn {
    padding: 10px 35px;
  }
}
